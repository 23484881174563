<template>
  <section class="hero is-medium is-info">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          Acerca de
        </h1>
        <h2 class="subtitle">
          Drive Privado Para Academia YesInSpain
        </h2>

        Mantenido por <a target="_blank" class="has-text-warning"
                                  href="https://www.zaafaast.es">Zaafaa.es</a> usando
        <b-icon icon="vuejs"></b-icon>
        y
        <b-icon icon="language-php"></b-icon>
        <br>
        <a target="_blank" class="has-text-warning" href="https://www.zaafaast.es">Sigenos aquí</a> y
        ver
        <a class="has-text-warning" href="https://www.zaafaast.es" target="_blank">más
          más proyectos aquí</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AcercaDe"
}
</script>
