<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="is-size-2">Mis Carpetas</h2>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-table :data="carpetas" :loading="cargando">
          <template>
            <b-table-column searchable field="nombre" label="Nombre Carpeta" sortable v-slot="props">
               {{ props.row.nombre}}
            </b-table-column>
            <b-table-column searchable field="grupo" label="Grupo" sortable v-slot="props">
              {{ props.row.grupo}}
            </b-table-column>
           <b-table-column field="id" label="Opciones" v-slot="props">
              <b-dropdown :triggers="['click']" aria-role="list">
                <template #trigger>
                  <b-button
                      rounded
                      outlined
                      type="is-text"
                  >
                  <b-icon icon="dots-vertical"></b-icon>
                  </b-button>
                </template>
                <b-dropdown-item @click="seleccionar(props.row.id)" aria-role="listitem">
                  <b-icon icon="folder"></b-icon>&nbsp;seleccionar Carpeta
                </b-dropdown-item>
                </b-dropdown>
            </b-table-column>

           </template> 
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ArchivosService from "@/services/ArchivosService";
//import Constantes from "@/Constantes";
//import Utiles from "@/Utiles";
//import NotificacionesService from "@/services/NotificacionesService";
import EventBus from "@/EventBus";
export default {
  name: "VerCarpetas",
  
  data: () => ({
    carpetas: [],
    cargando: false,
  }),
  mounted() {
    this.obtenerCarpetas();
  },
  methods: {
   
    async obtenerCarpetas() {
      this.cargando = true;
      this.carpetas = await ArchivosService.obtenerCarpetas();
      this.cargando = false;
    },
    async seleccionar(id) {
      this.cargando = true;
      await ArchivosService.selcarpeta(id);
      this.cargando = false;
      EventBus.$emit("navegarHacia", "VerArchivos");
    },
    
  },

}
</script>
